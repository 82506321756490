<template>
  <div>
    <div v-if="entitySummaries && entitySummaries.length === 0 && ecomType === 'audanet'">
      Insurer <span class="has-text-primary has-text-weight-bold">{{ insurerName }}</span> cannot be not found. Continuing will create this insurer.
    </div>
    <div v-else>
      <div class="is-narrow"
        v-if="enableEcomTypeSelection">
        <label class="label">E-Com Type</label>
        <div class="control">
          <div class="field is-horizontal is-flex">
            <div class="control select">
              <select v-model="innerEcomType"
                class="select-width">
                <option value="all"
                  :selected="innerEcomType == 'all'">ALL</option>
                <option :value="ecomTypeEnum.ORM">ORM</option>
                <option :value="ecomTypeEnum.PNET">PNET</option>
              </select>
            </div>
            <div class="control">
              <input class="input"
                type="text"
                v-model="innerInsurerName"
                placeholder="Insurer Name">
            </div>
            <div class="control">
              <button class="button is-primary"
                @click="handleSearch">
                <span class="icon is-small">
                  <i class="mdi mdi-magnify" />
                </span>
                <span>Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <label class="label">Please select an insurer</label>
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isTableLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        @pageChanged="onPageChange"
        :show-pagination="false"
        @sort="sort">
        <tr v-for="(entity, index) in entitySummaries"
          :key="entity.messageId"
          :class="{ 'is-selected' : selectedRow === index }"
          @click="highlightSelected(index, $event)">
          <td>
            {{ entity.name }}
          </td>
          <td class="has-text-centered">
            {{ entity.repairerId }}
          </td>
          <td class="has-text-centered">
            <span class="tag"
              :class="[ entity.quotingMethod === 'D' ? 'is-success' : 'is-info' ]">{{ entity.quotingMethod }}</span>
          </td>
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
      <span class="has-text-danger"
        v-if="$v.loadOptions.insurerId.$error">
        <span class="icon has-text-danger">
          <i class="mdi mdi-alert-octagram mdi-24px" />
        </span>
        Please select an insurer before continuing.
        {{ $v.loadOptions.insurerId.$error }}
      </span>
      <span class="has-text-danger"
        v-if="entitySummaries && entitySummaries.length === 0">
        <span class="icon has-text-danger">
          <i class="mdi mdi-alert-octagram mdi-24px" />
        </span>
        <span v-if="ecomType === 'audanet'">Cannot find insurer
          <span class="has-text-weight-bold">{{ insurerName }}</span>
        </span>
        <span v-else>Cannot find insurers that has been setup for {{ ecomType.toUpperCase() }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { InsurerColumns } from './columns'
import LoadQuoteService from './LoadQuoteService'
import { EcomTypes } from '@/enums'
// import Guid from '@/components/Guid'

export default {
  name: 'LoadQuoteInsurerStep',
  inject: ['$vv'],
  components: {
    BulmaTable
  },
  props: {
    value: null,
    ecomType: {
      type: String
    },
    insurerName: {
      type: String,
      default: ''
    },
    enableEcomTypeSelection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      entitySummaries: null,
      totalRows: 0,
      selectedRow: null,
      isTableLoading: false,
      innerEcomType: this.ecomType,
      innerInsurerName: this.insurerName,
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 10
      },
      innerValue: this.value
    }
  },
  computed: {
    columns() {
      return InsurerColumns
    },
    $v() {
      return this.$vv || this.$v
    },
    ecomTypeEnum: function () {
      return EcomTypes
    }
  },
  watch: {
    innerValue: function (newVal, oldVal) {
      this.$emit('input', newVal)
    },
    innerEcomType: function (newVal, oldVal) {
      // this.$emit('input', newVal)
      this.getEntitySummaries()
    }
  },
  created() {
    this.innerEcomType = this.ecomType
    this.innerInsurerName = this.insurerName == '' ? '' : this.insurerName
    this.getEntitySummaries()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
      this.innerValue.insurerId = this.entitySummaries[index].insurerId
    },
    onPageChange(pageIndex) {},
    sort() {},
    async getEntitySummaries() {
      this.isTableLoading = true
      this.entitySummaries = await LoadQuoteService.getInsurerSummaries(this.innerEcomType, this.innerInsurerName)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.innerValue.isCreateInsurer = this.entitySummaries && this.entitySummaries.length === 0 && this.ecomType === 'audanet'
      this.isTableLoading = false
    },
    handleSearch() {
      this.getEntitySummaries()
    }
  }
}
</script>

<style scoped>
.field.is-flex {
  display: flex;
  align-items: center;
}
.field.is-flex .control {
  flex: 1;
}
.field.is-flex .control.select {
  flex: 0 0 auto;
  margin-right: 1rem;
}
/* .field.is-flex .control .button {
  flex: 0 0 auto;
  margin-left: 1rem;
} */
</style>