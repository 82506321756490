<template>
  <div>
    <div>
      <div class="columns">
        <label class="column label">Select from Below</label>
        <input
          class="column input is-pulled-right is-hidden"
          type="text"
          placeholder="Search"
          v-model="searchTextField"
          @keyup.enter="filterQuotes()">
      </div>
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isTableLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        @pageChanged="onPageChange"
        :show-pagination="false"
        @sort="sort">
        <tr v-for="(entity, index) in entitySummaries"
          :key="entity.quoteNo"
          :class="{ 'is-selected' : selectedRow === index }"
          @click="highlightSelected(index, $event)">
          <td>
            {{ entity.quoteNoRef }}
          </td>
          <td>
            {{ entity.regoNo }}
          </td>
          <td>
            {{ entity.make }}
          </td>
          <td>
            {{ entity.model }}
          </td>
          <td>
            {{ entity.claimNo }}
          </td>
          <td>
            {{ entity.jobStageText }}
          </td>
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </div>
    <span class="has-text-danger"
      v-if="invalidQuoteId">
      <span class="icon has-text-danger">
        <i class="mdi mdi-alert-octagram mdi-24px" />
      </span>
      Please select a quote before continuing.
    </span>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import LoadQuoteService from './LoadQuoteService'
import { QuoteColumns } from './columns'

export default {
  name: 'LoadQuoteAssign',
  inject: ['$vv'],
  components: {
    BulmaTable
  },
  props: {
    value: null,
    ecomType: {
      type: String
    },
    regoNo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      entitySummaries: null,
      totalRows: 0,
      selectedRow: null,
      isTableLoading: false,
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 10
      },
      innerValue: this.value,
      searchTextField: null
    }
  },
  computed: {
    columns() {
      return QuoteColumns
    },
    $v() {
      return this.$vv || this.$v
    },
    invalidQuoteId() {
      return this.$v.loadOptions.quoteId.$error && !this.value.isCreateQuote
    }
  },
  watch: {
    innerValue: function (newVal, oldVal) {
      this.$emit('input', newVal)
    }
  },
  created() {
    this.getEntitySummaries()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
      this.innerValue.quoteId = this.entitySummaries[index].quoteId
      this.innerValue.customerId = this.entitySummaries[index].customerId
      this.innerValue.originalCustomerId = this.entitySummaries[index].customerId
      this.innerValue.vehicleId = this.entitySummaries[index].vehicleId
      if(this.ecomType == 'audanet') {
        this.innerValue.isAssignQuote = true
        this.innerValue.isOverwriteQuote = true
      }
    },
    onPageChange(pageIndex) {},
    sort() {},
    async getEntitySummaries() {
      this.isTableLoading = true
      this.entitySummaries = await LoadQuoteService.getQuoteSummaries(this.value.regoNo, this.value.claimNo, this.value.isRepx)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries.length : 0
      this.isTableLoading = false
    },
    async filterQuotes() {
      if (this.searchTextField != '') {
        console.log(this.searchTextField)
        var result = null
        const search = this.searchTextField.toUpperCase()
        result = this.entitySummaries.filter((x)=>{
          const regoNo = x.regoNo && x.regoNo.toUpperCase().includes(search)
          const claimNo = x.claimNo && x.claimNo.toUpperCase().includes(search)
          return regoNo || claimNo
        })
        this.entitySummaries = result
      }
      else
        this.entitySummaries = await LoadQuoteService.getQuoteSummaries(this.value.regoNo, this.value.claimNo, this.value.isRepx)
    }
  }

}
</script>
