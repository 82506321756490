import FqApi from '@/services/fq-api'

export default {
  async getQuoteIdByNo(quoteNo) {
    const result = await FqApi.get(`quotes/quoteidbyno?quoteNo=${quoteNo}`)
    return result.data
  },
  async getQuoteSummaries(rego, claimno, isrepx) {
    //const url = `/quotes/loadquote/quotelist?rego=${rego}&claimmo=${claimno}`
    if (isrepx == undefined || isrepx == null) isrepx = false
    const url = `/quotes/assignquote/${rego}/${claimno}/${isrepx}`
    const result = await FqApi.get(url)
    return result.data
  }
}
